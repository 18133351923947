import React, { useState, useEffect } from "react";
import { getCurrentBracket,  getBracketByID} from "../services/BracketService";

const BracketDisplay = (props) => {
    const title = props.currentBracketData?.title
    const desc = props.currentBracketData?.description
    const imgSrc = props.currentBracketData?.image
    const is_current = props.currentBracketData?.is_current? "true" : "false"
    const display_is_current = props.display_current
    return (
    <div>
        <h3>{title}</h3>
        <p>{desc}</p>
        <img src={imgSrc} alt={desc}></img>
        <p/>
        <a href={imgSrc} target="_blank" rel="noopener noreferrer">source</a>
        {display_is_current && <p>is current: {is_current}</p>}
    </div>
    )
}

const currentBracketNotFound = () => {
    return (
    <div>
        <h3>No Current Bracket Found - Come back later</h3>
    </div>
    )
}

export function CurrentBracket (getToken) {
    const [ currentBracket, setBracket ] = useState([])
    const [ notFound, setNotFound ] = useState(false)
    useEffect(() => {
        const token = getToken.getToken()
        getCurrentBracket(token).then((res) => {
            if(res?.status === 404){
                setNotFound(true)
            }
            setBracket(res)
        }).catch((e) => {
            console.log('nya: ' + e.message)
        })
    }, [getToken])

    if(notFound){
        return currentBracketNotFound()
    }
    
    return (
        <div>
            <BracketDisplay currentBracketData = {currentBracket} display_current = {false}/>
        </div>
    )
}

export function BracketID (bracketID, getToken) {
    const [ idBracket, setIDBracket ] = useState([])
    useEffect(() => {
        const token = getToken()
        getBracketByID(token, bracketID).then((res) => {
            setIDBracket(res)
        }).catch((e) => {
            console.log('nya: ' + e.message)
        })
    }, [getToken, bracketID])
    
    return (
        <div>
            <BracketDisplay currentBracketData = {idBracket} display_current = {true}/>
        </div>
    )
}
