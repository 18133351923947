import React, { useState } from 'react';
import { login } from '../services/LoginService';

//temporary page title for navigation purposes
const Title = () => {
    return <h1 className="App-header">Login Page</h1>
}

const LoginForm = ({setToken}) => {
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [errorVisibility, setErrorVisibility] = useState();

    
    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const token = await login(username, password);
            setToken(token);
        } catch (error) {
            setErrorVisibility(true)
            console.error(error)
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <input placeholder="Username" onChange={e => setUsername(e.target.value)}></input>
                <input type="password" placeholder="Password" onChange={e => setPassword(e.target.value)}></input>
                <button type="submit">Login</button>
            </form>
            {errorVisibility
                ? (<span className='Error-text'>Come back when you got some valid credentials, buddy</span>) 
                : (<span></span>)}
            
        </div>
    )
}

function LoginPage({setToken}) {
    return (
        <div className="Login">
            <Title />
            <LoginForm setToken={setToken}/>
        </div>
    )
}

export default LoginPage
