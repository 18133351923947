import React, { useState, useEffect } from "react";
import { getCurrentPoll,  getPollByID, voteCurrentPoll} from "../services/PollService";

const PollDisplay = (props) => {
    var users_have_voted = null
    const setVotedFor = props.setVotedFor
    const votedFor1 = props.votedFor?.votedFor1
    const votedFor2 = props.votedFor?.votedFor2

    async function voteForPoll(getToken, entry1vote, entry2vote){
        try{
          const token = getToken()
          await voteCurrentPoll(token, entry1vote, entry2vote)
          if(entry1vote){
            setVotedFor({votedFor1: true, votedFor2: false})
          }
          if(entry2vote){
            setVotedFor({votedFor1: false, votedFor2: true})
          }
        } catch (e) {
          alert(e.message);
        }
      }

    const getToken = props.getToken
    const title = props.currentPollData?.title
    const desc = props.currentPollData?.description
    const entry_1_name = props.currentPollData?.entry_1_name
    const entry_1_image = props.currentPollData?.entry_1_image
    const entry_1_votes = props.currentPollData?.entry_1_votes
    const entry_2_name = props.currentPollData?.entry_2_name
    const entry_2_image = props.currentPollData?.entry_2_image
    const entry_2_votes = props.currentPollData?.entry_2_votes
    const is_open = props.currentPollData?.is_open? "true" : "false"
    const show_results = props.currentPollData?.show_results? "true" : "false"
    if(props.currentPollData){
        if(props.currentPollData.users_have_voted){
            users_have_voted = props.currentPollData.users_have_voted.map( (entry) => { return entry.username})
        }
    }
    return (
    <div>
        <h3>{title}</h3>
        <p>{desc}</p>
        <p>{entry_1_name}</p>
        <img src={entry_1_image} alt={entry_1_name}></img>
        <p/>
        <a href={entry_1_image} target="_blank" rel="noopener noreferrer">source</a>
        {!props.show_voting && !votedFor1 && <div> <button onClick={() => voteForPoll(getToken, true, false)}>Vote For ^</button> </div>}
        {!props.show_voting && votedFor1 && <p>You voted for {entry_1_name}</p>}
        {props.show_voting && <p>{entry_1_votes}</p>}
        <p>{entry_2_name}</p>
        <img src={entry_2_image} alt={entry_2_name}></img>
        <p/>
        <a href={entry_2_image} target="_blank" rel="noopener noreferrer">source</a>
        {!props.show_voting && !votedFor2 && <div> <button onClick={() => voteForPoll(getToken, false, true)}>Vote For ^</button> </div>}
        {!props.show_voting && votedFor2 && <p>You voted for {entry_2_name}</p>}
        {props.show_voting && <p>{entry_2_votes}</p>}
        {props.show_voting && <p>is open: {is_open}</p>}
        {props.show_voting && <p>show results: {show_results}</p>}
        {props.show_voting && <p>[{users_have_voted}]</p>}
    </div>
    )
}

const currentPollNotFound = () => {
    return (
    <div>
        <h3>No Current Poll Found - Come back later</h3>
    </div>
    )
}

export function CurrentPoll (props) {
    const getToken = props.getToken
    const getUser = props.getUser
    const [ currentPoll, setPoll ] = useState([])
    const [votedFor, setVotedFor] = useState({votedFor1: false, votedFor2: false});
    const [ notFound, setNotFound ] = useState(false)
    useEffect(() => {
        const token = getToken()
        getCurrentPoll(token).then((res) => {
            if(res?.status === 404){
                setNotFound(true)
            }
            setPoll(res)
            if(res){
                if(res.users_have_voted){
                    const id = getUser()?.user_id
                    for (let i = 0; i < res.users_have_voted.length; i++) {
                        if(res.users_have_voted[i].user_id === id){
                            if(res.users_have_voted[i].entry_voted === 1){
                                setVotedFor({votedFor1: true, votedFor2: false})
                            }else{
                                setVotedFor({votedFor1: false, votedFor2: true})
                            }
                        }
                    } 
                }
            }
        }).catch((e) => {
            console.log('nya: ' + e.message)
        })
    }, [getToken, getUser])

    if(notFound){
        return currentPollNotFound()
    }

    return (
        <div>
            <PollDisplay currentPollData = {currentPoll} show_voting={false} getToken={getToken} votedFor={votedFor} setVotedFor={setVotedFor}/>
        </div>
    )
}

export function PollID (pollID, getToken) {
    const [ idPoll, setIDPoll ] = useState([])
    useEffect(() => {
        const token = getToken()
        getPollByID(token, pollID).then((res) => {
            setIDPoll(res)
        }).catch((e) => {
            console.log('nya: ' + e.message)
        })
    }, [getToken, pollID])
    
    return (
        <div>
            <PollDisplay currentPollData = {idPoll} show_voting={true} token={getToken} />
        </div>
    )
}
