import {CurrentFunnyMeter} from "../components/DisplayFunnyNumber";

//temporary page title for navigation purposes
const Title = () => {
    return <h1 className="App-header">Funny Meter</h1>
}

function FunnyNumberPage({getToken}) {
    return (
        <div className="Bracket">
            <Title />
            <CurrentFunnyMeter getToken={getToken}/>
        </div>
    )
}

export default FunnyNumberPage
