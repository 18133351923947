
import {useParams} from "react-router-dom";
import { updatePassword, adminUpdatePassword} from "../services/LoginService";
import { useState } from 'react';

export function UserPasswordChange(isVisible, setIsVisible, getToken) {

  const [passchange, setPasschange] = useState({
    old_password: "",
    new_password: "",
  });

  async function submitNewPassword(getToken, old_password, new_password){
    try{
      const token = getToken()
      await updatePassword(token, old_password, new_password);
      setIsVisible(false);
      setPasschange({old_password: "", new_password: ""});
    } catch (e) {
      alert(e.message);
    }
  }

  function handleOldPasswordChange(e) {
    setPasschange({
      ...passchange,
      old_password: e.target.value
    });
  }

  function handleNewPasswordChange(e) {
    setPasschange({
      ...passchange,
      new_password: e.target.value
    });
  }

  if(!isVisible){
    return (<></>)
  }
  
  return (
    <>
      <label>
        Old Password:
        <input
          type="password"
          value={passchange.old_password}
          onChange={handleOldPasswordChange}
        />
      </label>
      <label>
        New Password:
        <input
          type="password"
          value={passchange.new_password}
          onChange={handleNewPasswordChange}
        />
      </label>
      <button onClick={() => submitNewPassword(getToken, passchange.old_password, passchange.new_password)}>Submit</button>
    </>
  );
}

export function AdminPasswordChange(isVisible, setIsVisible, getToken) {
  const {userID} = useParams();
  const [newPassword, setNewPassword] = useState("");

  async function submitNewPassword(getToken, newPassword){
    try{
      const token = getToken()
      await adminUpdatePassword(token, userID, newPassword);
      setIsVisible(false);
      setNewPassword("");
    } catch (e) {
      alert(e.message);
    }
  }

  function handleNewAdminPasswordChange(e) {
    setNewPassword(e.target.value);
  }

  if(!isVisible){
    return (<></>)
  }
  
  return (
    <>
      <label>
        New Password:
        <input
          type="password"
          value={newPassword}
          onChange={handleNewAdminPasswordChange}
        />
      </label>
      <button onClick={() => submitNewPassword(getToken, newPassword)}>Submit</button>
    </>
  );
}
