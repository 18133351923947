
import { useState, useEffect } from 'react';
import {useParams, useNavigate} from "react-router-dom";
import { getPollByID, postPollByID, postPoll} from "../services/PollService";

export function NewPoll({getToken}) {
  const nav = useNavigate();

  async function submitNew(getToken, poll){
    try{
      const token = getToken()
      const resp = await postPoll(token, poll)
      nav("/polls/"+resp.id)
    } catch (e) {
      alert(e.message);
    }
  }

  const [poll, setPoll] = useState({
    title: "",
    desc: "",
    entry_1_name: "",
    entry_1_image: "",
    entry_1_votes: "",
    entry_2_name: "",
    entry_2_image: "",
    entry_2_votes: "",
    is_open: false,
    show_results: false,
    users_have_voted: [],
  });
  
  function submitNewPoll(poll){
    submitNew(getToken, poll)
  }
  
  return PollForm(poll, setPoll, submitNewPoll)
}

export function EditPoll({getToken}) {
  const {pollID} = useParams();
  const nav = useNavigate();

  async function submitEdit(getToken, pollID, poll){
    try{
      const token = getToken()
      await postPollByID(token, pollID, poll)
      nav("/polls/"+pollID)
    } catch (e) {
      alert(e.message);
    }
  }

  const [poll, setPoll] = useState({
    title: "",
    desc: "",
    entry_1_name: "",
    entry_1_image: "",
    entry_1_votes: "",
    entry_2_name: "",
    entry_2_image: "",
    entry_2_votes: "",
    is_open: false,
    show_results: false,
    users_have_voted: [],
  });

  function submitPollEdit(poll){
    console.log(poll)
    submitEdit(getToken, pollID, poll)
  }

  useEffect( () => { 
    async function fetchData() {
        try {
            const token = getToken()
            const poll = await getPollByID(token, pollID)
            setPoll(poll);
        } catch (err) {
            console.log(err);
        }
    }
    fetchData();
}, [getToken, pollID]);
  
  return PollForm(poll, setPoll, submitPollEdit)
}

function PollForm(poll, setPoll, onClick){

  function handleTitleChange(e) {
    setPoll({
      ...poll,
      title: e.target.value
    });
  }

  function handleDescChange(e) {
    setPoll({
      ...poll,
      description: e.target.value
    });
  }

  function handleEntry1NameChange(e) {
    setPoll({
      ...poll,
      entry_1_name: e.target.value
    });
  }
  
  function handleEntry1ImageChange(e) {
    setPoll({
      ...poll,
      entry_1_image: e.target.value
    });
  }

  function handleEntry2NameChange(e) {
    setPoll({
      ...poll,
      entry_2_name: e.target.value
    });
  }
  
  function handleEntry2ImageChange(e) {
    setPoll({
      ...poll,
      entry_2_image: e.target.value
    });
  }

  return (
    <>
      <label>
        Title:
        <input
          value={poll.title}
          onChange={handleTitleChange}
        />
      </label>
      <label>
        Description:
        <input
          value={poll.description}
          onChange={handleDescChange}
        />
      </label>
      <label>
        Entry 1's Name:
        <input
          value={poll.entry_1_name}
          onChange={handleEntry1NameChange}
        />
      </label>
      <label>
        Entry 1's Image:
        <input
          value={poll.entry_1_image}
          onChange={handleEntry1ImageChange}
        />
      </label>
      <label>
        Entry 2's Name:
        <input
          value={poll.entry_2_name}
          onChange={handleEntry2NameChange}
        />
      </label>
      <label>
        Entry 2's Image:
        <input
          value={poll.entry_2_image}
          onChange={handleEntry2ImageChange}
        />
      </label>
      <button onClick={() => onClick(poll)}>Submit</button>
      <p>
        <i>{poll.title}</i>
        <br />
        {poll.description}
      </p>
      <p>
        {poll.entry_1_name}
        <br />
        <img 
          src={poll.entry_1_image} 
          alt={poll.entry_1_name}
        />
      </p>
      <p>
        {poll.entry_2_name}
        <br />
        <img 
          src={poll.entry_2_image} 
          alt={poll.entry_2_name}
        />
      </p>
    </>
  );
}