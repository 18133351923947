import { CurrentPoll } from "../components/DisplayPoll"

//temporary page title for navigation purposes
const Title = () => {
    return <h1 className="App-header">Poll</h1>
}

function CurrentPollPage(props) {
    return (
        <div className="Poll">
            <Title />
            <CurrentPoll getToken={props.getToken} getUser={props.getUser}/>
        </div>
    )
}

export default CurrentPollPage
