import ListUsers from "../components/UserList";
import {Link} from "react-router-dom";

//temporary page title for navigation purposes
const Title = () => {
    return <h1 className="App-header">User List</h1>
}
  

function UserListPage({getToken}) {
    return (
        <div>
            <Link to={`/users/new`}>New</Link>
            <div className="User">
                <Title />
                <ListUsers getToken={getToken}/>
            </div>
        </div>
    )
}

export default UserListPage
