import {CurrentBracket} from "../components/DisplayBracket";

//temporary page title for navigation purposes
const Title = () => {
    return <h1 className="App-header">Bracket</h1>
}

function CurrentBracketPage({getToken}) {
    return (
        <div className="Bracket">
            <Title />
            <CurrentBracket getToken={getToken}/>
        </div>
    )
}

export default CurrentBracketPage
