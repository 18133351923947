import {CurrentUser} from "../components/DisplayUser";
import {UserPasswordChange} from "../components/EditPassword";
import { useState } from 'react';

//temporary page title for navigation purposes
const Title = () => {
    return <h1 className="App-header">User</h1>
}

function CurrentUserPage({getToken}) {
    const [showPassword, setShowPassword] = useState(false);

    function swapShowPassword(){
        setShowPassword(!showPassword)
      }
    
    return (
        <div className="User">
            <Title />
            <CurrentUser getToken={getToken}/>
        <div>
        <button onClick={() => swapShowPassword()}>Change Password</button> <span/>
        </div>
        {UserPasswordChange(showPassword, setShowPassword, getToken)}
        </div>
    )
}

export default CurrentUserPage
