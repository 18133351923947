
import { useState, useEffect } from 'react';
import {useParams, useNavigate} from "react-router-dom";
import { getUser, updateUserByID, createUser} from "../services/LoginService";

export function NewUser({getToken}) {
  const nav = useNavigate();

  async function submitNew(getToken, user){
    try{
      const token = getToken()
      const resp = await createUser(token, user)
      nav("/users/"+resp.user_id)
    } catch (e) {
      alert(e.message);
    }
  }

  const [user, setUser] = useState({
    id: 0,
    username: "",
    password: "",
    image: "",
    is_admin: false,
  });
  
  function submitNewUser(user){
    submitNew(getToken, user)
  }
  
  return UserForm(user, setUser, submitNewUser, true)
}

export function EditUser({getToken}) {
  const {userID} = useParams();
  const nav = useNavigate();

  async function submitEdit(getToken, userID, user){
    try{
      const token = getToken()
      await updateUserByID(token, userID, user)
      nav("/users/"+userID)
    } catch (e) {
      alert(e.message);
    }
  }

  const [user, setUser] = useState({
    id: 0,
    username: "",
    password: "",
    image: "",
    is_admin: false,
  });

  function submitUserEdit(user){
    console.log(user)
    submitEdit(getToken, userID, user)
  }

  useEffect( () => { 
    async function fetchData() {
        try {
            const token = getToken()
            const user = await getUser(token, userID)
            setUser(user);
        } catch (err) {
            console.log(err);
        }
    }
    fetchData();
}, [getToken, userID]);
  
  return UserForm(user, setUser, submitUserEdit, false)
}

function UserForm(user, setUser, onClick, showPassword){

  function handleUsernameChange(e) {
    setUser({
      ...user,
      username: e.target.value
    });
  }

  function handlePasswordChange(e) {
    setUser({
      ...user,
      password: e.target.value
    });
  }

  function handleIsAdminChange(e) {
    setUser({
      ...user,
      is_admin: e.target.checked
    });
  }

  function handleImageChange(e) {
    setUser({
      ...user,
      image: e.target.value
    });
  }

  return (
    <>
      <label>
        Username:
        <input
          value={user.username}
          onChange={handleUsernameChange}
        />
      </label>
      {showPassword && <label>
        Password:
        <input
          value={user.password}
          onChange={handlePasswordChange}
        />
      </label>}
      <label>
        Admin:
        <input type="checkbox"
          checked={user.is_admin}
          onChange={handleIsAdminChange}
        />
      </label>
      <label>
        Image:
        <input
          value={user.image}
          onChange={handleImageChange}
        />
      </label>
      <button onClick={() => onClick(user)}>Submit</button>
      <p>
        <i>{user.username}</i>
      </p>
      <img 
        src={user.image} 
        alt={user.username}
      />
    </>
  );
}