import {PollID} from "../components/DisplayPoll";
import {useParams, Link, useNavigate} from "react-router-dom";
import { deletePollByID, openPollByID, closePollByID, clearPollByID, showPollResultsByID } from "../services/PollService";

//temporary page title for navigation purposes
const Title = () => {
    return <h1 className="App-header">Poll</h1>
}

function CurrentPollPage({getToken}) {
  const {pollID} = useParams();
  const nav = useNavigate();

  async function submitDelete(getToken, pollID){
      try{
        const token = getToken()
        await deletePollByID(token, pollID)
        nav("/polls")
      } catch (e) {
        alert(e.message);
      }
    }

    async function submitOpenPoll(getToken, pollID){
      try{
        const token = getToken()
        await openPollByID(token, pollID)
        nav("/polls")
      } catch (e) {
        alert(e.message);
      }
    }

    async function submitClosePoll(getToken, pollID, show_results){
      try{
        const token = getToken()
        await closePollByID(token, pollID, show_results)
        nav("/polls")
      } catch (e) {
        alert(e.message);
      }
    }

    async function submitClearPoll(getToken, pollID){
      try{
        const token = getToken()
        await clearPollByID(token, pollID)
        nav("/polls")
      } catch (e) {
        alert(e.message);
      }
    }

    async function submitShowResultsPoll(getToken, pollID){
      try{
        const token = getToken()
        await showPollResultsByID(token, pollID)
        nav("/polls")
      } catch (e) {
        alert(e.message);
      }
    }

  return (
      <div className="Poll">
          <Title />
          {PollID(pollID, getToken)}
          <p>{pollID}</p>

      <div>
          <Link to={`/polls/${pollID}/edit`}>edit</Link> <span/>
          <button onClick={() => submitOpenPoll(getToken, pollID)}>Open</button> <span/>
          <button onClick={() => submitClosePoll(getToken, pollID, false)}>Close</button> <span/>
          <button onClick={() => submitClosePoll(getToken, pollID, true)}>Close And Show Results</button> <span/>
          <button onClick={() => submitShowResultsPoll(getToken, pollID)}>Show Results</button> <span/>
          <button onClick={() => submitClearPoll(getToken, pollID)}>Clear</button> <span/>
          <button onClick={() => submitDelete(getToken, pollID)}>Delete</button>
      </div>
      </div>
  )
}

export default CurrentPollPage
