import {EditBracket, NewBracket} from "../components/EditBracket";
import {useParams} from "react-router-dom";

//temporary page title for navigation purposes
const Title = () => {
    return <h1 className="App-header">Bracket</h1>
}

export function EditBracketPage({getToken}) {
    const {bracketID} = useParams();
    return (
        <div className="Bracket">
            <Title />
            <EditBracket getToken={getToken}/>
            <p>{bracketID}</p>
        </div>
    )
}

export function NewBracketPage({getToken}) {
    return (
        <div className="Bracket">
            <Title />
            <NewBracket getToken={getToken}/>
        </div>
    )
}
