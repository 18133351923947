import {CurrentPollResults} from "../components/DisplayPollResults";

//temporary page title for navigation purposes
const Title = () => {
    return <h1 className="App-header">Poll Results</h1>
}

function PollResultsPage({getToken}) {

    return (
        <div className="Poll">
            <Title />
            <CurrentPollResults getToken={getToken}/>
        </div>
    )
}

export default PollResultsPage
