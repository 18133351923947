import {EditPoll, NewPoll} from "../components/EditPoll";
import {useParams} from "react-router-dom";

//temporary page title for navigation purposes
const Title = () => {
    return <h1 className="App-header">Poll</h1>
}

export function EditPollPage({getToken}) {
    const {pollID} = useParams();
    return (
        <div className="Poll">
            <Title />
            <EditPoll getToken={getToken}/>
            <p>{pollID}</p>
        </div>
    )
}

export function NewPollPage({getToken}) {
    return (
        <div className="Poll">
            <Title />
            <NewPoll getToken={getToken}/>
        </div>
    )
}
