const baseUrl = 'https://backend.lasagnalouvre.com'

export async function login(username, password) {
    const path = '/login'
    try {
        const response = await fetch(baseUrl + path, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "username": username,
                "password": password
            })
        })
        if(!response.ok) {
            throw new Error("Network response was not OK")
        }
        return response.json()
    } catch (error) {
        throw new Error("Login Failed")
    }
}

export async function getAccount(token) {
    const path = '/account'
    try {
        const response = await fetch(baseUrl + path, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        return response.json();
    } catch (error) {
        console.log('Failed to get account: ' + error)
        return [];
    }
}

export async function getUser(token, userId) {
    const path = '/user/';
    try {
        const response = await fetch (baseUrl + path + userId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });
        return await response.json();
    } catch (error) {
        console.log('Failed to get user: ' + error);
        return [];
    }
}

export async function listUsers(token) {
    const path = '/user';
    try {
        const response = await fetch (baseUrl + path, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });
        return await response.json();
    } catch (error) {
        console.log('Failed to get user: ' + error);
        return [];
    }
}

export async function createUser(token, user) {
    const path = '/user'
    try {
        const response = await fetch(baseUrl + path, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(user)
        })

        return response.json();
    } catch (error) {
        console.log('Failed to create user: ' + error)
        return [];
    }
}

export async function updateUserByID(token, id, user) {
    const path = '/user/'
    try {
        const response = await fetch(baseUrl + path + id, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(user)
        })

        return response.json();
    } catch (error) {
        console.log('Failed to update user by id: ' + error)
        return [];
    }
}

export async function deleteUserByID(token, id) {
    const path = '/user/'
    try {
        const response = await fetch(baseUrl + path + id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

        return response.ok;
    } catch (error) {
        console.log('Failed to delete user by id: ' + error)
        return [];
    }
}

export async function updatePassword(token, oldPassword, newPassword) {
    const path = '/update_password'
    try {
        const response = await fetch(baseUrl + path, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: "{ \"old_password\": \"" + oldPassword + "\", \"new_password\": \"" + newPassword + "\" }"
        })

        return response.json();
    } catch (error) {
        console.log('Failed to update password for account: ' + error)
        return [];
    }
}

export async function adminUpdatePassword(token, id, newPassword) {
    const path = '/update_password/'
    try {
        const response = await fetch(baseUrl + path + id, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: "{ \"new_password\": \"" + newPassword + "\" }"
        })

        return response.json();
    } catch (error) {
        console.log('Failed to update password for user by id: ' + error)
        return [];
    }
}