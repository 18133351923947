const baseUrl = 'https://backend.lasagnalouvre.com'

export async function getFunnyNumber(token) {
    const path = '/funnynumber';
    try {
        const response = await fetch(baseUrl + path, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        return response.json();
    } catch (error) {
        console.log('Failed to get funny number: ' + error);
        return [];
    }
}

export async function voteFunnyNumber(token, funnynumber) {
    const path = '/funnynumber'
    try {
        const response = await fetch(baseUrl + path, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: "{ \"funny_number\": " + funnynumber + " }"
        })

        return response.json();
    } catch (error) {
        console.log('Failed to vote funny number: ' + error)
        return [];
    }
}

export async function startFunnyNumberSess(token, name) {
    const path = '/funnynumber/start'
    try {
        const response = await fetch(baseUrl + path, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: "{ \"session_name\": \"" + name + "\" }"
        })

        return response.json();
    } catch (error) {
        console.log('Failed to start funny number: ' + error)
        return [];
    }
}

export async function endFunnyNumberSess(token) {
    const path = '/funnynumber/end'
    try {
        const response = await fetch(baseUrl + path, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

        return response.json();
    } catch (error) {
        console.log('Failed to end funny number: ' + error)
        return [];
    }
}
