import React, { useState, useEffect } from "react";
import { listPolls } from "../services/PollService";
import { Link } from "react-router-dom";

const ListPollDisplay = (props) => {
    const listPollItems = props.listPollsData.polls?.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase())).map((poll) =>
        <li><Link to={`/polls/${poll.id}`} key={poll.id} >{poll.title}</Link></li>
      );
    return (
    <div>
        {listPollItems}
    </div>
    )
}

function ListPolls ({getToken}) {
    const [ pollList, setPoll ] = useState([])
    useEffect(() => {
        const token = getToken()
        listPolls(token).then((res) => {
            setPoll(res)
        }).catch((e) => {
            console.log('nya: ' + e.message)
        })
    }, [getToken])
    
    return (
        <div>
            <ListPollDisplay listPollsData = {pollList} />
        </div>
    )
}

export default ListPolls