import React, { useState, useEffect } from "react";
import { getMessageOfDay } from "../services/TestService";

const MessageHeader = () => {
    return <h1>Message of the Day:</h1>
}

const FunnyMessage = (props) => {
    const message = props.funnyMessageData.message
    return <h3>{message}</h3>
}

function MessageOfTheDay () {
    const [ funniestMessage, setMessage ] = useState([])
    useEffect(() => {
        getMessageOfDay().then((res) => {
            setMessage(res)
        }).catch((e) => {
            console.log('nya: ' + e.message)
        })
    }, [])
    
    return (
        <div>
            <MessageHeader />
            <FunnyMessage funnyMessageData = {funniestMessage} />
        </div>
    )
}

export default MessageOfTheDay
