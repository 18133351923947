import React, { useState, useEffect } from "react";
import { getFunnyNumber,  voteFunnyNumber} from "../services/FunnyService";

const BracketDisplay = (props) => {
    const getToken = props.getToken
    const [ funnyTimestamp, setTimestamp ] = useState(TimeRightNowMS())

    async function voteForFunny(getToken, funnyNumber){
        if(funnyTimestamp < TimeRightNowMS()){
            try{
              const token = getToken.getToken()
              await voteFunnyNumber(token, funnyNumber)
              setTimestamp(TimeRightNowMS() + 3000) //don't allow another vote for 3 seconds
            } catch (e) {
              alert(e.message);
            }
        }
      }
    
    const imgSrc = props.currentFunnyData?.image
    return (
    <div>
        <img src={imgSrc} alt={"funny haha meter"}></img>
        <p/>
        <button onClick={() => voteForFunny(getToken, -6)}> - 2 </button> 
        <button onClick={() => voteForFunny(getToken, 6)}> + 2 </button>
    </div>
    )
}

const currentFunnyNotFound = () => {
    return (
    <div>
        <h3>No Funny Yet - Come back later</h3>
    </div>
    )
}

export function CurrentFunnyMeter (getToken) {
    const [ currentFunny, setFunny ] = useState([])
    const [ notFound, setNotFound ] = useState(false)

    useEffect(() => {
        const token = getToken.getToken()
        getFunnyNumber(token).then((res) => {
            if(res?.status === 404){
                setNotFound(true)
            }
            setFunny(res)
        }).catch((e) => {
            console.log('nya: ' + e.message)
        })
        const interval = setInterval(() => getFunnyNumber(token).then((res) => {
            if(res?.status === 404){
                setNotFound(true)
            }
            setFunny(res)
        }).catch((e) => {
            console.log('nya: ' + e.message)
        }), 10000); //this refreshes the data every 10 seconds
        return () => {
            clearInterval(interval);
        };
    }, [getToken])

    if(notFound){
        return currentFunnyNotFound()
    }
    
    return (
        <div>
            <BracketDisplay currentFunnyData = {currentFunny} getToken={getToken} />
        </div>
    )
}

export function TimeRightNowMS(){
    return Math.round((new Date()).getTime());
  }
