import React, { useState, useEffect } from "react";
import { listUsers } from "../services/LoginService";
import { Link } from "react-router-dom";

const ListUserDisplay = (props) => {
    const listUserItems = props.listUsersData.users?.sort((a, b) => a.username.toLowerCase().localeCompare(b.username.toLowerCase())).map((user) =>
        <li><Link to={`/users/${user.user_id}`} key={user.user_id} >{user.username}</Link></li>
      );
    return (
    <div>
        {listUserItems}
    </div>
    )
}

function ListUsers ({getToken}) {
    const [ userList, setUser ] = useState([])
    useEffect(() => {
        const token = getToken()
        listUsers(token).then((res) => {
            setUser(res)
        }).catch((e) => {
            console.log('nya: ' + e.message)
        })
    }, [getToken])
    
    return (
        <div>
            <ListUserDisplay listUsersData = {userList} />
        </div>
    )
}

export default ListUsers