import {EditUser, NewUser} from "../components/EditUser";
import {useParams} from "react-router-dom";

//temporary page title for navigation purposes
const Title = () => {
    return <h1 className="App-header">User</h1>
}

export function EditUserPage({getToken}) {
    const {userID} = useParams();
    return (
        <div className="User">
            <Title />
            <EditUser getToken={getToken}/>
            <p>{userID}</p>
        </div>
    )
}

export function NewUserPage({getToken}) {
    return (
        <div className="User">
            <Title />
            <NewUser getToken={getToken}/>
        </div>
    )
}
