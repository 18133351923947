import React, { useState, useEffect } from "react";
import { getPollResults } from "../services/PollService";

const PollResultsDisplay = (props) => {
    const title = props.currentPollData?.title
    const entry_1_name = props.currentPollData?.entry_1_name
    const entry_1_image = props.currentPollData?.entry_1_image
    const entry_1_votes = props.currentPollData?.entry_1_votes
    const entry_2_name = props.currentPollData?.entry_2_name
    const entry_2_image = props.currentPollData?.entry_2_image
    const entry_2_votes = props.currentPollData?.entry_2_votes

    var winTitle = entry_2_name + " won " + title + "!"
    var winName = entry_2_name
    var winImage = entry_2_image
    var loseTitle = "Sorry " + entry_1_name + "! Better luck next year :P ;PP"
    var loseName = entry_1_name
    var loseImage = entry_1_image
    if(entry_1_votes === entry_2_votes){
        winTitle = entry_2_name + " and " + entry_1_name + " tied!"
        loseTitle = ""
    }else if(entry_1_votes > entry_2_votes){
        winTitle = entry_1_name + " won " + title + "!"
        winName = entry_1_name
        winImage = entry_1_image
        loseTitle = "Sorry " + entry_2_name + "! Better luck next year :P ;PP"
        loseName = entry_2_name
        loseImage = entry_2_image
    }

    return (
    <div>
        <h3>{winTitle}</h3>
        <p>{winName}</p>
        <img src={winImage} alt={winName}></img>
        <p/>
        <a href={entry_1_image} target="_blank" rel="noopener noreferrer">source</a>
        <p>{loseTitle}</p>
        <p>{loseName}</p>
        <img src={loseImage} alt={loseName}></img>
        <p/>
        <a href={loseImage} target="_blank" rel="noopener noreferrer">source</a>
    </div>
    )
}

const resultsNotFound = () => {
    return (
    <div>
        <h3>No Poll Results Found - Come back later</h3>
    </div>
    )
}

export function CurrentPollResults ({getToken}) {
    const [ currentPoll, setPoll ] = useState([])
    const [ notFound, setNotFound ] = useState(false)
    useEffect(() => {
        const token = getToken()
        getPollResults(token).then((res) => {
            if(res?.status === 404){
                setNotFound(true)
            }
            setPoll(res)
        }).catch((e) => {
            console.log('nya: ' + e.message)
        })
    }, [getToken])

    if(notFound){
        return resultsNotFound()
    }
    
    return (
        <div>
            <PollResultsDisplay currentPollData = {currentPoll} />
        </div>
    )
}
