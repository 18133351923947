import React, { useState, useEffect } from "react";
import { getAccount,  getUser} from "../services/LoginService";

const UserDisplay = (props) => {
    const user_id = props.currentUserData?.user_id
    const username = props.currentUserData?.username
    const imgSrc = props.currentUserData?.image
    const is_admin = props.currentUserData?.is_admin? "true" : "false"
    return (
    <div>
        <h3>{username}</h3>
        <p>user id: {user_id}</p>
        <p/>
        <img src={imgSrc} alt={user_id}></img>
        <p/>
        <a href={imgSrc} target="_blank" rel="noopener noreferrer">source</a>
        <p/>
        <p>is admin: {is_admin}</p>
    </div>
    )
}

export function CurrentUser (getToken) {
    const [ currentUser, setUser ] = useState([])
    useEffect(() => {
        const token = getToken.getToken()
        getAccount(token).then((res) => {
            setUser(res)
        }).catch((e) => {
            console.log('nya: ' + e.message)
        })
    }, [getToken])
    
    return (
        <div>
            <UserDisplay currentUserData = {currentUser} />
        </div>
    )
}

export function UserID (userID, getToken) {
    const [ idUser, setIDUser ] = useState([])
    useEffect(() => {
        const token = getToken()
        getUser(token, userID).then((res) => {
            setIDUser(res)
        }).catch((e) => {
            console.log('nya: ' + e.message)
        })
    }, [getToken, userID])
    
    return (
        <div>
            <UserDisplay currentUserData = {idUser} />
        </div>
    )
}
