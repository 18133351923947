import { useEffect } from "react";
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";

export function Layout(props){
  const nav = useNavigate();
  const path = useLocation().pathname
  const is_admin = props.getUser()?.is_admin
  const deleteToken = props.deleteToken

  useEffect( () => {
    if(path === '/') {
      nav('/home');
    }
  })

  return (
    <>
      <nav className="Navigation">
        <ul className="nav-list">
          <li>
            <Link to={`/home`}>Home Page</Link>
          </li>
          <li>
            <Link to={`/bracket`}>Bracket</Link>
          </li>
          <li>
            <Link to={`/poll`}>Poll</Link>
          </li>
          <li>
            <Link to={`/poll_results`}>Poll Results</Link>
          </li>
          <li>
            <Link to={`/account`}>Account</Link>
          </li>
          <li>
            <Link to={`/funny_number`}>Funny</Link>
          </li>
          <li>
            <Link to={`/error`}>Error</Link>
          </li>
          <li>
            <button onClick={() => deleteToken()}>Logout</button>
          </li>
        </ul>
          {is_admin && 
          <ul className="nav-list">
          <li>
            <Link to={`/brackets`}>Bracket List</Link>
          </li>
          <li>
            <Link to={`/polls`}>Poll List</Link>
          </li>
          <li>
            <Link to={`/users`}>User List</Link>
          </li>
          <li>
            <Link to={`/funny_number_admin`}>Funny Admin</Link>
          </li>
          </ul>}
        
      </nav>
      <div >
        <Outlet />
      </div>
    </>
  )
};

export default Layout;
