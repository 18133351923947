import {UserID} from "../components/DisplayUser";
import {useParams, Link, useNavigate} from "react-router-dom";
import { deleteUserByID } from "../services/LoginService";
import {AdminPasswordChange} from "../components/EditPassword";
import { useState } from 'react';

//temporary page title for navigation purposes
const Title = () => {
    return <h1 className="App-header">User</h1>
}

function CurrentUserPage({getToken}) {
  const {userID} = useParams();
  const nav = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  async function submitDelete(getToken, userID){
      try{
        const token = getToken()
        await deleteUserByID(token, userID)
        nav("/users")
      } catch (e) {
        alert(e.message);
      }
    }

    function swapShowPassword(){
      setShowPassword(!showPassword)
    }

  return (
      <div className="User">
          <Title />
          {UserID(userID, getToken)}
          <p>{userID}</p>

      <div>
          <Link to={`/users/${userID}/edit`}>edit</Link> <span/>
          <button onClick={() => swapShowPassword()}>Change Password</button> <span/>
          <button onClick={() => submitDelete(getToken, userID)}>Delete</button>
      </div>
      {AdminPasswordChange(showPassword, setShowPassword, getToken)}
      </div>
  )
}

export default CurrentUserPage
