const baseUrl = 'https://backend.lasagnalouvre.com'

export function getTestCurrentBracket() {
    const testResponse = {
        'id': 0,
        'title': 'Test Bracket',
        'description': 'this is a test bracket',
        'image': 'Funnier Message',
        'is_current': true
    }
    return testResponse
}

export async function getCurrentBracket(token) {
    const path = '/current_bracket'
    try {
        const response = await fetch(baseUrl + path, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

        return response.json();
    } catch (error) {
        console.log('Failed to get current bracket: ' + error)
        return [];
    }
}

export async function listBrackets(token) {
    const path = '/bracket'
    try {
        const response = await fetch(baseUrl + path, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

        return response.json();
    } catch (error) {
        console.log('Failed to get bracket list: ' + error)
        return [];
    }
}

export async function getBracketByID(token, id) {
    const path = '/bracket/'
    try {
        const response = await fetch(baseUrl + path + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

        return response.json();
    } catch (error) {
        console.log('Failed to get bracket by id: ' + error)
        return [];
    }
}

export async function postBracket(token, bracket) {
    const path = '/bracket'
    try {
        const response = await fetch(baseUrl + path, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(bracket)
        })

        return response.json();
    } catch (error) {
        console.log('Failed to create bracket: ' + error)
        return [];
    }
}

export async function postBracketByID(token, id, bracket) {
    const path = '/bracket/'
    try {
        const response = await fetch(baseUrl + path + id, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(bracket)
        })

        return response.json();
    } catch (error) {
        console.log('Failed to update bracket by id: ' + error)
        return [];
    }
}

export async function deleteBracketByID(token, id) {
    const path = '/bracket/'
    try {
        const response = await fetch(baseUrl + path + id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

        return response.json();
    } catch (error) {
        console.log('Failed to get bracket by id: ' + error)
        return [];
    }
}

export async function setCurrentBracketByID(token, id) {
    const path = '/current_bracket/'
    try {
        const response = await fetch(baseUrl + path + id, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

        return response.json();
    } catch (error) {
        console.log('Failed to get bracket by id: ' + error)
        return [];
    }
}