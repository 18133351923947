import {CurrentFunnyMeter} from "../components/DisplayFunnyNumber";
import { startFunnyNumberSess,  endFunnyNumberSess} from "../services/FunnyService";
import { useState } from 'react';

//temporary page title for navigation purposes
const Title = () => {
    return <h1 className="App-header">Funny Meter</h1>
}

function FunnyNumberAdminPage({getToken}) {

    const [name, setName] = useState("");

    async function startSess(getToken, name){
        try{
            const token = getToken()
            await startFunnyNumberSess(token, name)
        } catch (e) {
            alert(e.message);
        }
    }

    async function endSess(getToken){
        try{
            const token = getToken()
            await endFunnyNumberSess(token)
        } catch (e) {
            alert(e.message);   
        }
    }

    function handleNameChange(e) {
        setName(e.target.value);
    }

    return (
        <div className="Bracket">
            <Title />
            <CurrentFunnyMeter getToken={getToken}/>
            <label>
            Session Name:
                <input
                value={name}
                onChange={handleNameChange}
                />
            </label> 
          <button onClick={() => startSess(getToken, name)}>Start</button> <span/>
          <button onClick={() => endSess(getToken)}>End</button>
        </div>
    )
}

export default FunnyNumberAdminPage
