import React from "react";
import logo from '../assets/PipePrizeLogo_5.svg';
import MessageOfTheDay from "../components/MessageOfTheDay";

//temporary page title for navigation purposes
const Title = () => {
    return <h1 className="App-header">Home Page</h1>
}

const FunnyLogo = () => {
    return <img src={logo} className="App-logo" alt="logo" />
}

function HomePage() {

    return (
        <div className="Homepage">
            <Title />
            <FunnyLogo />
            <MessageOfTheDay />
        </div>
    )
    
}

export default HomePage
