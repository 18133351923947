import ListBrackets from "../components/BracketList";
import {Link} from "react-router-dom";

//temporary page title for navigation purposes
const Title = () => {
    return <h1 className="App-header">Bracket List</h1>
}
  

function BracketListPage({getToken}) {
    return (
        <div>
            <Link to={`/brackets/new`}>New</Link>
            <div className="Bracket">
                <Title />
                <ListBrackets getToken={getToken}/>
            </div>
        </div>
    )
}

export default BracketListPage
