import {BracketID} from "../components/DisplayBracket";
import {useParams, Link, useNavigate} from "react-router-dom";
import { deleteBracketByID, setCurrentBracketByID } from "../services/BracketService";

//temporary page title for navigation purposes
const Title = () => {
    return <h1 className="App-header">Bracket</h1>
}

function CurrentBracketPage({getToken}) {
  const {bracketID} = useParams();
  const nav = useNavigate();

  async function submitDelete(bracketID, getToken){
    console.log(bracketID)
      try{
        const token = getToken()
        await deleteBracketByID(token, bracketID)
        nav("/brackets")
      } catch (e) {
        alert(e.message);
      }
    }

    async function submitSetCurrent(bracketID, getToken){
      try{
        const token = getToken()
        await setCurrentBracketByID(token, bracketID)
        nav("/brackets")
      } catch (e) {
        alert(e.message);
      }
    }

  return (
      <div className="Bracket">
          <Title />
          {BracketID(bracketID, getToken)}
          <p>{bracketID}</p>

      <div>
          <Link to={`/brackets/${bracketID}/edit`}>edit</Link> <span/>
          <button onClick={() => submitSetCurrent(bracketID, getToken)}>Set To Current</button> <span/>
          <button onClick={() => submitDelete(bracketID, getToken)}>Delete</button>
      </div>
      </div>
  )
}

export default CurrentBracketPage
