import './App.css';
import { 
  createBrowserRouter, 
  RouterProvider 
} from 'react-router-dom';
import ErrorPage from './pages/ErrorPage';
import Layout from './pages/Layout';
import HomePage from './pages/HomePage'
import CurrentBracketPage from './pages/CurrentBracket';
import BracketIDPage from './pages/BracketIDPage';
import {EditBracketPage, NewBracketPage} from './pages/EditBracketPage';
import BracketListPage from './pages/BracketListPage';
import CurrentPollPage from './pages/CurrentPoll';
import PollIDPage from './pages/PollIDPage';
import {EditPollPage, NewPollPage} from './pages/EditPollPage';
import PollListPage from './pages/PollListPage';
import PollResultsPage from './pages/PollResultsPage';
import LoginPage from './pages/Login';
import CurrentUserPage from './pages/CurrentUser';
import UserIDPage from './pages/UserIDPage';
import {EditUserPage, NewUserPage} from './pages/EditUserPage';
import UserListPage from './pages/UserListPage';
import FunnyNumberPage from './pages/FunnyNumberPage';
import FunnyNumberAdminPage from './pages/FunnyNumberAdminPage';
import useToken from './hooks/useToken';

function App() {
  const { token, setToken, deleteToken, getUser } = useToken();

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout deleteToken={deleteToken} getUser={getUser} />,
      errorElement: <ErrorPage />,
      children:[
        {
          path: "/home",
          element: <HomePage />,
        },
        {
          path: "/bracket",
          element: <CurrentBracketPage getToken={token}/>,
        },
        {
          path: "/brackets",
          element: <BracketListPage getToken={token}/>,
        },
        {
          path: "/brackets/:bracketID/edit",
          element: <EditBracketPage getToken={token}/>,
        },
        {
          path: "/brackets/:bracketID",
          element: <BracketIDPage getToken={token}/>,
        },
        {
          path: "/brackets/new",
          element: <NewBracketPage getToken={token}/>,
        },
        {
          path: "/poll",
          element: <CurrentPollPage getToken={token} getUser={getUser}/>,
        },
        {
          path: "/polls",
          element: <PollListPage getToken={token}/>,
        },
        {
          path: "/polls/:pollID/edit",
          element: <EditPollPage getToken={token}/>,
        },
        {
          path: "/polls/:pollID",
          element: <PollIDPage getToken={token}/>,
        },
        {
          path: "/polls/new",
          element: <NewPollPage getToken={token}/>,
        },
        {
          path: "/poll_results",
          element: <PollResultsPage getToken={token}/>,
        },
        {
          path: "/account",
          element: <CurrentUserPage getToken={token}/>,
        },
        {
          path: "/users",
          element: <UserListPage getToken={token}/>,
        },
        {
          path: "/users/:userID/edit",
          element: <EditUserPage getToken={token}/>,
        },
        {
          path: "/users/:userID",
          element: <UserIDPage getToken={token}/>,
        },
        {
          path: "/users/new",
          element: <NewUserPage getToken={token}/>,
        },
        {
          path: "/funny_number",
          element: <FunnyNumberPage getToken={token}/>,
        },
        {
          path: "/funny_number_admin",
          element: <FunnyNumberAdminPage getToken={token}/>,
        },
        {
          path: "/error",
          element: <ErrorPage />,
        },
      ]
    },
  ]);
  
  if(!token()) {
    return <LoginPage setToken={setToken} />
  }
  console.log(token())
  
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  )
}

export default App;
