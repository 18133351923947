import { useState } from "react";

export default function useToken() {
    const getToken = () => {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        if (!userToken) {
          return null
        }
        return userToken.token
      }

    const [tok, setToken] = useState(getToken());

    const saveToken = (userToken) => {
        if(localStorage.getItem('token') != null){
          localStorage.removeItem('token');
        }
        userToken.expires_in = userToken.expires_in + TimeRightNowMS()
        localStorage.setItem('token', JSON.stringify(userToken));
        setToken(userToken.token);
      }

      const removeToken = () => {
        localStorage.removeItem('token');
        setToken(null);
      }

      const getUserInfo = () => {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        if (!userToken) {
          return null
        }
        return userToken.user
      }

      function validToken() {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        if(userToken && (userToken.expires_in - TimeRightNowMS() < 0)){
          removeToken()
        }
        return tok
      }

      return {
        setToken: saveToken,
        deleteToken: removeToken,
        token: validToken,
        getUser: getUserInfo
      }
}

export function TimeRightNowMS(){
  return Math.round((new Date()).getTime());
}