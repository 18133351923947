import React, { useState, useEffect } from "react";
import { listBrackets } from "../services/BracketService";
import { Link } from "react-router-dom";

const ListBracketDisplay = (props) => {
    const listBracketItems = props.listBracketsData.brackets?.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase())).map((bracket) =>
        <li><Link to={`/brackets/${bracket.id}`} key={bracket.id} >{bracket.title}</Link></li>
      );
    return (
    <div>
        {listBracketItems}
    </div>
    )
}

function ListBrackets ({getToken}) {
    const [ bracketList, setBracket ] = useState([])
    useEffect(() => {
        const token = getToken()
        listBrackets(token).then((res) => {
            setBracket(res)
        }).catch((e) => {
            console.log('nya: ' + e.message)
        })
    }, [getToken])
    
    return (
        <div>
            <ListBracketDisplay listBracketsData = {bracketList} />
        </div>
    )
}

export default ListBrackets