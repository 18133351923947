import ListPolls from "../components/PollList";
import {Link} from "react-router-dom";
import { clearCurrentPollResults } from "../services/PollService";

//temporary page title for navigation purposes
const Title = () => {
    return <h1 className="App-header">Poll List</h1>
}
  

function PollListPage({getToken}) {

    async function submitClearShowResults(getToken){
        try{
          const token = getToken()
          await clearCurrentPollResults(token)
        } catch (e) {
          alert(e.message);
        }
    }

    return (
        <div>
            <Link to={`/polls/new`}>New</Link> <span/>
            <button onClick={() => submitClearShowResults(getToken)}>Clear Current Polls Results</button> <span/>
            <div className="Poll">
                <Title />
                <ListPolls getToken={getToken}/>
            </div>
        </div>
    )
}

export default PollListPage
