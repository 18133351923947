const baseUrl = 'https://backend.lasagnalouvre.com'

export async function getMessageOfDay() {
    const path = '/messageofday';
    try {
        const response = await fetch (baseUrl + path)
        return response.json();
    } catch (error) {
        console.log('Failed to get message of the day: ' + error);
        return [];
    }
}

export function getTestMessage() {
    const testResponse = {
        'id': 0,
        'message': 'Funnier Message'
    }
    return testResponse
}

export async function getCatFact() {
    const catFactUrl = 'https://catfact.ninja/fact';
    try {
        const response = await fetch (catFactUrl);
        return response.json()
    } catch (error) {
        console.log('Failed to get cat fact: ' + error);
        return [];
    }
}
