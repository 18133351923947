const baseUrl = 'https://backend.lasagnalouvre.com'

export function getTestCurrentPoll() {
    const testResponse = {
        'id': 0,
        'title': 'Test Poll',
        'description': 'this is a test poll',
        'image': 'Funnier Message',
        'is_current': true
    }
    return testResponse
}

export async function listPolls(token) {
    const path = '/poll'
    try {
        const response = await fetch(baseUrl + path, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

        return response.json();
    } catch (error) {
        console.log('Failed to get poll list: ' + error)
        return [];
    }
}

export async function getPollByID(token, id) {
    const path = '/poll/'
    try {
        const response = await fetch(baseUrl + path + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

        return response.json();
    } catch (error) {
        console.log('Failed to get poll by id: ' + error)
        return [];
    }
}

export async function postPoll(token, poll) {
    const path = '/poll'
    try {
        const response = await fetch(baseUrl + path, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(poll)
        })

        return response.json();
    } catch (error) {
        console.log('Failed to create poll: ' + error)
        return [];
    }
}

export async function postPollByID(token, id, poll) {
    const path = '/poll/'
    try {
        const response = await fetch(baseUrl + path + id, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(poll)
        })

        return response.json();
    } catch (error) {
        console.log('Failed to update poll by id: ' + error)
        return [];
    }
}

export async function deletePollByID(token, id) {
    const path = '/poll/'
    try {
        const response = await fetch(baseUrl + path + id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

        return response.ok;
    } catch (error) {
        console.log('Failed to delete poll by id: ' + error)
        return [];
    }
}

export async function openPollByID(token, id) {
    const path = '/poll/' + id + '/open'
    try {
        const response = await fetch(baseUrl + path, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

        return response.json();
    } catch (error) {
        console.log('Failed to open poll by id: ' + error)
        return [];
    }
}

export async function closePollByID(token, id, show_results) {
    const path = '/poll/' + id + '/close'
    try {
        // console.log("{ \"show_results\": \"" + show_results + "\" }")
        const response = await fetch(baseUrl + path, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: "{ \"show_results\": " + show_results + " }"
        })

        return response.json();
    } catch (error) {
        console.log('Failed to close poll by id: ' + error)
        return [];
    }
}

export async function clearPollByID(token, id) {
    const path = '/poll/' + id + '/clear'
    try {
        const response = await fetch(baseUrl + path, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

        return response.json();
    } catch (error) {
        console.log('Failed to clear poll by id: ' + error)
        return [];
    }
}

export async function showPollResultsByID(token, id) {
    const path = '/poll/' + id + '/show_results'
    try {
        const response = await fetch(baseUrl + path, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

        return response.json();
    } catch (error) {
        console.log('Failed to open show results by id: ' + error)
        return [];
    }
}

export async function getCurrentPoll(token) {
    const path = '/current_poll'
    try {
        const response = await fetch(baseUrl + path, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

        return response.json();
    } catch (error) {
        console.log('Failed to get current poll: ' + error)
        return [];
    }
}

export async function getPollResults(token) {
    const path = '/poll_results'
    try {
        const response = await fetch(baseUrl + path, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

        return response.json();
    } catch (error) {
        console.log('Failed to get current poll results: ' + error)
        return [];
    }
}

export async function clearCurrentPollResults(token) {
    const path = '/clear_poll_results'
    try {
        const response = await fetch(baseUrl + path, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })

        return response.json();
    } catch (error) {
        console.log('Failed to clear current show poll results: ' + error)
        return [];
    }
}

export async function voteCurrentPoll(token, entry1vote, entry2vote) {
    const path = '/current_poll'
    try {
        // console.log("{ \"entry_1_vote\": \"" + entry1vote + ", \"entry_2_vote\": \"" + entry2vote + "\" }")
        const response = await fetch(baseUrl + path, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: "{ \"entry_1_vote\": " + entry1vote + ", \"entry_2_vote\": " + entry2vote + " }"
        })

        return response.json();
    } catch (error) {
        console.log('Failed to vote for current poll: ' + error)
        return [];
    }
}
