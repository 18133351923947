
import { useState, useEffect } from 'react';
import {useParams, useNavigate} from "react-router-dom";
import { getBracketByID, postBracketByID, postBracket} from "../services/BracketService";

export function NewBracket({getToken}) {
  const nav = useNavigate();

  async function submitNew(getToken, bracket){
    try{
      const token = getToken()
      const resp = await postBracket(token, bracket)
      nav("/brackets/"+resp.id)
    } catch (e) {
      alert(e.message);
    }
  }

  const [bracket, setBracket] = useState({
    id: 0,
    title: "",
    description: "",
    image: "",
    is_current: false,
  });
  
  function submitNewBracket(bracket){
    submitNew(getToken, bracket)
  }
  
  return BracketForm(bracket, setBracket, submitNewBracket)
}

export function EditBracket({getToken}) {
  const {bracketID} = useParams();
  const nav = useNavigate();

  async function submitEdit(getToken, bracketID, bracket){
    try{
      const token = getToken()
      await postBracketByID(token, bracketID, bracket)
      nav("/brackets/"+bracketID)
    } catch (e) {
      alert(e.message);
    }
  }

  const [bracket, setBracket] = useState({
    id: 0,
    title: "",
    description: "",
    image: "",
    is_current: false,
  });

  function submitBracketEdit(bracket){
    console.log(bracket)
    submitEdit(getToken, bracketID, bracket)
  }

  useEffect( () => { 
    async function fetchData() {
        try {
            const token = getToken()
            const bracket = await getBracketByID(token, bracketID)
            setBracket(bracket);
        } catch (err) {
            console.log(err);
        }
    }
    fetchData();
}, [getToken, bracketID]);
  
  return BracketForm(bracket, setBracket, submitBracketEdit)
}

function BracketForm(bracket, setBracket, onClick){

  function handleTitleChange(e) {
    setBracket({
      ...bracket,
      title: e.target.value
    });
  }

  function handleDescChange(e) {
    setBracket({
      ...bracket,
      description: e.target.value
    });
  }

  function handleImageChange(e) {
    setBracket({
      ...bracket,
      image: e.target.value
    });
  }

  return (
    <>
      <label>
        Title:
        <input
          value={bracket.title}
          onChange={handleTitleChange}
        />
      </label>
      <label>
        Description:
        <input
          value={bracket.description}
          onChange={handleDescChange}
        />
      </label>
      <label>
        Image:
        <input
          value={bracket.image}
          onChange={handleImageChange}
        />
      </label>
      <button onClick={() => onClick(bracket)}>Submit</button>
      <p>
        <i>{bracket.title}</i>
        <br />
        {bracket.description}
      </p>
      <img 
        src={bracket.image} 
        alt={bracket.title}
      />
    </>
  );
}